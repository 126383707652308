import React, { useState } from 'react'
import { Grid, Typography, Select } from '@material-ui/core'
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function RightSideBar({setFile}) {
  const volume = useSelector(state => state.stlSlicer.volume)
  const [material,setMaterial] = useState('PLA')


    const handleFile = (e)=>{
      const reader = new FileReader()

      reader.readAsDataURL(e.target.files[0])
  
      reader.onload = () => {
       
      setFile(reader.result)
      }

      console.log(e.target.files)
    }




  return (

     <Grid container spacing={3}>

        <Grid item xs={10} style={{height:'100%',width:'70%',display:'flex',justifyContent:'center',flexDirection:'column'}}>
        <Typography variant='h6' align='center' style={{marginBottom:10}}>Upload STL file per calcolare il costo di stampa</Typography>
        <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
            Upload file
      <VisuallyHiddenInput type="file" accept='.stl' onChange={handleFile} />
    </Button>

      <Select variant='outlined' style={{marginTop:10}}   value={material}
      label="Age"
      onChange={(val)=>setMaterial(val.target.value)}

    >
      <MenuItem value={"PLA"}>PLA</MenuItem>
      <MenuItem value={"ABS"}>ABS</MenuItem>
      <MenuItem value={"PETG"}>PETG</MenuItem>
      </Select>        


      <Typography variant='h6' align='center' style={{marginTop:10}}><b>Volume:</b> {volume}</Typography>


        </Grid>
        </Grid>


    )
}

export default RightSideBar