import CanvasEngine from './components/Canvas'
import './App.css';
import {Grid} from '@material-ui/core';
import RightSideBar from './components/RightSideBar'
import { useState } from 'react';
function App() {

  const [file,setFile] = useState(null)

  return (
      <Grid container spacing={3}>
        <Grid item xs={12} style={{backgroundColor:'#fff',height:'20vh',display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
          <img src='/images/logo.png' alt='logo' style={{marginLeft:20}}/>
          </Grid>
        
        <Grid item xs={8}  style={{height:'82vh',borderRight:'solid 2px #0000'}}>
      <CanvasEngine file={file}/>

        </Grid>
        <Grid item xs={4} style={{backgroundColor:'#fff'}}>
          <RightSideBar setFile={setFile}/>

          </Grid>
        </Grid>
 );
}

export default App;
