import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  volume: 0,
}

export const stlSlicer = createSlice({
  name: 'stlSlicer',
  initialState,
  reducers: {
    setValue: (state,action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.volume=action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setValue } = stlSlicer.actions

export default stlSlicer.reducer