import React, { Suspense,  useEffect } from 'react'
import {Canvas} from '@react-three/fiber'
import {OrbitControls} from '@react-three/drei'
import {STLLoader} from "three/examples/jsm/loaders/STLLoader";
import { useLoader} from "@react-three/fiber";
import * as THREE from 'three'
import { setValue } from '../features/stlSlicer';
import { useDispatch } from 'react-redux';

function CanvasEngine({file}) {
  const geom = useLoader(STLLoader, file || '/model/MilleniumFalcon2.STL');
  const refCamera = React.useRef()
  const dispatch = useDispatch()


  function getVolume(geometry) {
  let position = geometry.attributes.position;
    let faces = position.count / 3;
    let sum = 0;
    let p1 = new THREE.Vector3(),
      p2 = new THREE.Vector3(),
      p3 = new THREE.Vector3();
    for (let i = 0; i < faces; i++) {
      p1.fromBufferAttribute(position, i * 3 + 0);
      p2.fromBufferAttribute(position, i * 3 + 1);
      p3.fromBufferAttribute(position, i * 3 + 2);
      sum += signedVolumeOfTriangle(p1, p2, p3);
    }
    return sum;
  
  }
  

  function signedVolumeOfTriangle(p1, p2, p3) {
    return p1.dot(p2.cross(p3)) / 6.0;
  }


  useEffect(()=>{
    const volume = getVolume(geom)
      dispatch(setValue(volume))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ geom])
  

  return (
      <>
      <Canvas style={{height:'100%',width:'100%'}}>
       <Suspense fallback={null}>
            <mesh position={[0,0,0]}>
            <primitive object={geom} attach="geometry"/>
              <meshStandardMaterial color={"white"}/>
            </mesh>
          </Suspense>
       <directionalLight position={[0,0,0]} intensity={1}/>
       <directionalLight position={[-10,0,-10]} intensity={1}/>
       <directionalLight position={[10,0,10]} intensity={1}/>
<directionalLight position={[10,0,-10]} intensity={1}/>
<directionalLight position={[-10,0,10]} intensity={1}/>

      <OrbitControls ref={refCamera} position={new THREE.Vector3(0,0,0)} position0={[0,0,0]} target={[140,89,-8]} />
    </Canvas>
    </>

    )
}

export default CanvasEngine